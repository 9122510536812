<template>
  <section class="page-portugal">
    <div class="banner">
      <img
        class="logo"
        src="//static.hoolihome.com/global-jwp/assets/m/fund/portugal/portugal-logo.png"
      />
      <p class="title">
        <span>JWP FUND</span>
      </p>
    </div>
    <div class="textcontent" :class="{ fold: isFold }">
      <p>
        Portugal is known as the paradise of Europe's Iberian Peninsula, and
        when international investors have not yet discovered the potential of
        Portuguese investment, the JWP team has preemptively entered the blue
        ocean market of Portuguese real estate in 2011 and tapped the first pot
        of gold for real estate investment. Two years later, the business
        expanded and a local Portuguese branch was established. After the
        Portuguese AIMA enacted a new policy that fund investments can apply for
        a golden residence permit，The JWP Fund team designed and developed the
        JWP Portugal Fund in accordance with the regulatory requirements of the
        Portuguese Securities Market Commission (CMVM) and the European Central
        Bank (ECB). The fund is a closed-end private equity fund that invests in
        potential commercial companies in Portugal. Fund advantages: not only
        provides diversified asset allocation services for high-net-worth
        individuals, but also helps investors successfully obtain EU status. The
        fund investment strategy is deeply researched by our senior financial
        team, focusing on core assets in core areas, with value-added attributes
        and stable returns, crossing investment cycles, resisting inflation
        risks, and achieving a win-win situation for investment and identity.
      </p>
    </div>
    <div class="approval">
      <div class="look-more">
        <div class="opacity" v-if="isFold">
          <!-- <img src="//static.hoolihome.com/global-jwp/assets/m/text-mask.png" /> -->
        </div>
        <div class="btn" @click="handleLookMore">
          <span>{{ btnText }}</span>
          <img
            :src="
              isFold
                ? '//static.hoolihome.com/global-jwp/assets/m/arrow-more-down.png'
                : '//static.hoolihome.com/global-jwp/assets/m/arrow-more-up.png'
            "
          />
        </div>
      </div>
    </div>

    <div class="approval">
      <ul class="boxs">
        <li class="box">
          <van-image
            fit="contain"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/fund/portugal/approval-1.jpg"
          />
        </li>
        <li class="box">
          <van-image
            fit="contain"
            lazy-load
            src="//static.hoolihome.com/global-jwp/assets/m/fund/portugal/approval-2.jpg"
          />
        </li>
        <li class="box">
          <van-image
            fit="contain"
            lazy-load
            src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/portugal/part1-3.png"
          />
        </li>
      </ul>
      <p class="hd">Fund Certificate issued by CMVM</p>
    </div>
    <div class="act">
      <p class="title">
        <span class="van-hairline--bottom"> Fund Investment Act</span>
      </p>

      <div class="content">
        <div class="box">
          <!-- <div class="subtitle">
            <span>2023.10.07</span>
          </div> -->
          <div class="info">
            <p class="row-info-1">Act No. 56/2023 of October 7, 2023</p>
            <p class="row-info-2">
              The "golden visa" in Portugal has officially abolished the
              investment categories of 1.5 million euros in deposits, 500,000
              euros in house purchases, and 280,000/350,000 euros in renovated
              properties. The new law allows investment of no less than 500,000
              euros in funds established in Portugal for the acquisition of some
              <span>non real estate collective investment enterprises</span>
              , and requires the fund to invest at least 60% in commercial
              companies headquartered in Portugal, with an investment period of
              at least 5 years.
            </p>
          </div>
        </div>

        <div class="box">
          <!-- <div class="subtitle">
            <span>2021.02.12</span>
          </div> -->
          <div class="info">
            <p class="row-info-1">Act No. 14/2021 of February 12, 2021</p>
            <p class="row-info-2">
              The Residence Permits for Investment Activities' region (ARI) has
              been revised. Effective from January 1, 2022: The minimum
              investment requirement for fund options has been adjusted from
              350,000 euros to <span>500,000 euros</span>.
            </p>
          </div>
        </div>
        <div class="box">
          <!-- <div class="subtitle">
            <span>2017.11.26</span>
          </div> -->
          <div class="info">
            <p class="row-info-1">
              Act No. 102/2017, which came into force on 26 November 2017
            </p>
            <p class="row-info-2">
              Allowing non EU citizens to obtain Portuguese "golden visa"
              through capital transfer investment. Non EU citizens who apply for
              gold visa under this law should invest at least
              <span>350,000 euros</span> to purchase a company's capitalized
              investment fund or venture capital fund. Its investment period is
              at least five years, and at least 60% of the investment is
              realized through commercial companies headquartered in Portugal.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="choose">
      <p class="title">
        <span class="van-hairline--bottom"> Why choose JWP Fund</span>
      </p>
      <div class="content">
        <div class="box">
          <div class="num">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/portugal/01.png"
              alt=""
            />
          </div>
          <div class="info">
            <p>
              While most international investors have yet to discover the
              potential of Portugal, the Iberian pearl, JWP has benefited from
              encouraging real estate investment results since 2011 and
              established a subsidiary in 2013. In response to the latest
              Portuguese Golden Visa programme, JWP has announced the
              establishment of the JWP Golden Fund, regulated by the Portuguese
              Securities Market Commission (CMVM) and the European Central Bank.
            </p>
          </div>
        </div>
        <div class="box">
          <div class="num">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/portugal/02.png"
              alt=""
            />
          </div>
          <div class="info">
            <p>
              The fund is a closed-end private equity fund dedicated to
              capitalizing Portugal-based companies, offering high net worth
              individuals an attractive portfolio of investments and Portuguese
              residency.
            </p>
          </div>
        </div>
        <div class="box">
          <div class="num">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/portugal/03.png"
              alt=""
            />
          </div>
          <div class="info">
            <p>
              Competent financial experts and dedicated research analysts have
              developed JWP Golden Fund's primary investment strategy focused on
              uncovering unrealized growth opportunities and hidden value in a
              booming real estate market.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="elements">
      <p class="title">
        <span class="van-hairline--bottom">Fund Elements</span>
      </p>
      <div class="content">
        <img
          class="img1"
          src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/part4-1_en.png?v=2"
          alt=""
        />
        <img
          class="img2"
          src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/part4-2_en.png?v=2"
          alt=""
        />
      </div>
    </div>
    <div class="elements">
      <div class="title">
        <span>Fund Investment Strategy-Real<br /> </span>
        <span class="van-hairline--bottom"> Estate Investment Fund </span>
      </div>
      <div class="box-wrap-container">
        <div class="box-wrap">
          <div class="box-title">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/A.png"
              alt=""
            />
          </div>
          <p class="row-1">Fund investment direction</p>
          <p class="row-2">
            Enterprises with high growth, development, appreciation, and
            profitability in the market they operate in;
          </p>
        </div>
        <div class="box-wrap">
          <div class="box-title">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/B.png"
              alt=""
            />
          </div>
          <p class="row-1">investment strategies</p>
          <p class="row-2">
            administrative services related to tourism, hotel management,
            service apartments, joint office spaces, logistics, etc;
          </p>
        </div>
        <div class="box-wrap">
          <div class="box-title">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/C.png"
              alt=""
            />
          </div>
          <p class="row-1">Unleveraged</p>
          <p class="row-2">100% of the fund's own funds;</p>
        </div>
        <div class="box-wrap">
          <div class="box-title">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/D.png"
              alt=""
            />
          </div>
          <p class="row-1">Risk diversification</p>
          <p class="row-2">
            Adhere to diversified allocation, control the investment amount of
            individuaprojects, choose medium scale projects, diversify types and
            regions, reduce the selling risk duringexit. and enhance risk
            resistance;
          </p>
        </div>
        <div class="box-wrap">
          <div class="box-title">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/E.png"
              alt=""
            />
          </div>
          <p class="row-1">Strong asset liquidity</p>
          <p class="row-2">
            Compared to individual investors, funds mainly choose popular areas
            andimited supply of essentia projects that are popular among
            institutional investors to invest inensuring liquidity upon exit;
          </p>
        </div>
      </div>
      <div class="img-title">
        <img
          class="img"
          src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/part5-5_en.png"
          alt=""
        />
      </div>
      <div class="line">
        <div class="box"></div>
        <div class="box2"></div>
      </div>
      <div class="box-wrap-container">
        <div class="box-wrap">
          <div class="box-title">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/F.png"
              alt=""
            />
          </div>
          <p class="row-1">Clear exit path</p>
          <p class="row-2">
            The team has already started planning the exit path in the early
            stages of investment, establishing a database of potential buyers,
            and identifying future buyers for individual projects to lock in
            profits in advance;
          </p>
        </div>
        <div class="box-wrap">
          <div class="box-title">
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/G.png"
              alt=""
            />
          </div>
          <p class="row-1">Clear exit time</p>
          <p class="row-2">
            The fund product has a fixed cycle and a clear return time for
            funds, which facilitates the team to plan in advance and ensure
            sufficient cash flow of the fund at the end of the closure period.
            lnvestors do not need to worry about not being able to recover
            investment funds;
          </p>
        </div>
      </div>

      <div class="img-title2">
        <img
          class="img"
          src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/part5-6_en.png"
          alt=""
        />
      </div>
      <div class="desc">
        <img
          class="img"
          src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/shouhui.png"
          alt=""
        />
      </div>
      <div class="desc">
        <img
          class="img"
          src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/shouhui2.png"
          alt=""
        />
      </div>
      <div class="desc">
        <img
          class="img"
          src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/shouhui3.png"
          alt=""
        />
      </div>
    </div>
    <div class="advantages">
      <div class="title">
        <span class="van-hairline--bottom">JWP Golden Fund’s advantages</span>
      </div>
      <div class="content">
        <div class="box">
          <div class="image">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/1.png"
              alt=""
            />
          </div>
          <div class="info">
            The investment cost is low, and the fees are clear and transparent;
            the products are standardized, the investment amount can meet the
            fund share, and the fund has no taxes and additional maintenance
            costs;
          </div>
        </div>
        <div class="box">
          <div class="image">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/2.png"
              alt=""
            />
          </div>
          <div class="info">
            Safe and worry-free, strong supervision; the fund is supervised by
            the Portuguese Securities Regulatory Commission, the Portuguese
            Central Bank, and the European Central Bank, and the funds are
            supervised by professional depositary banks to ensure the safety of
            funds;
          </div>
        </div>
        <div class="box">
          <div class="image">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/3.png"
              alt=""
            />
          </div>
          <div class="info">
            Diversified investment allocation to effectively diversify risks;
            invest in commerical operation company , reduce restrictions on a
            single project and a single region, and improve the ability to
            resist risks;
          </div>
        </div>
        <div class="box">
          <div class="image">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/4.png"
              alt=""
            />
          </div>

          <div class="info">
            The investment and research system is sound and managed by a
            professional team; through in-depth research on immigration policies
            and local market research, make more professional judgments for
            investment;
          </div>
        </div>
        <div class="box">
          <div class="image">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/turkiye/5.png"
              alt=""
            />
          </div>
          <div class="info">
            Do not use leverage to maximize the security of the principal; all
            investment activities of the fund only use its own funds, and the
            investor is the only holder of the asset.
          </div>
        </div>
      </div>
    </div>
    <div class="introduction">
      <div class="title">
        <span class="van-hairline--bottom"
          >Introduction of the Fund has tendered</span
        >
      </div>
      <div class="content">
        <div class="item">
          <div class="name">Calcada do Combro</div>
          <div class="desc">
            -Located in the old town of Lisbon<br />
            -Assets worth approximately EUR 4 million<br />
            -All sold out
          </div>
          <div class="img">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p1.png"
              alt=""
            />
          </div>
          <div class="img2">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p2.png"
              alt=""
            />
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p3.png"
              alt=""
            />
          </div>
        </div>
        <div class="item">
          <div class="name">Campolide 87</div>
          <div class="desc">
            -Located in the old town of Lisbon<br />
            -Assets worth approximately EUR 4 million<br />
            -All sold out
          </div>
          <div class="img">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p4.png"
              alt=""
            />
          </div>
          <div class="img2">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p5.png"
              alt=""
            />
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p6.png"
              alt=""
            />
          </div>
        </div>
        <div class="item">
          <div class="name">Gomes Freire Building</div>
          <div class="desc">
            -Located in the old town of Lisbon<br />
            -Assets worth approximately EUR 4 million<br />
            -Sold out within a week
          </div>
          <div class="img">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p7.png"
              alt=""
            />
          </div>
          <div class="img2">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p8.png"
              alt=""
            />
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p9.png"
              alt=""
            />
          </div>
        </div>
        <div class="item">
          <div class="name">Actor Vale /Vale Living</div>
          <div class="desc">
            -Located in the old town of Lisbon<br />
            -Assets worth approximately EUR 6 million<br />
            -All sold out
          </div>
          <div class="img">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p10.png"
              alt=""
            />
          </div>
          <div class="img2">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p11.png"
              alt=""
            />
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p12.png"
              alt=""
            />
          </div>
        </div>
        <div class="item">
          <div class="name">Alges Boutique Apartments II</div>
          <div class="desc">
            -Located in Oeirache, Lisbon<br />
            -Located in the core tourist area of Portugal<br />
            -3 floors, a total of 6 European-style apartments<br />
            -The value of the assets is approximately EUR 3 million<br />
            -Acquired
          </div>
          <div class="img">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p13.png"
              alt=""
            />
          </div>
          <div class="img2">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p14.png"
              alt=""
            />
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p15.png"
              alt=""
            />
          </div>
        </div>
        <div class="item">
          <div class="name">Exe Essenzia Porto Hotel</div>
          <div class="desc">
            - Brand new 4 star hotel in the heart of Porto<br />
            - Assets worth over ten million euros<br />
            - Number of rooms: 80<br />
            - Management of international chain operators, signed a 15-year
            long-term contract
          </div>
          <div class="img">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p16.png"
              alt=""
            />
          </div>
          <div class="img2">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p17.png"
              alt=""
            />
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p18.png"
              alt=""
            />
          </div>
        </div>
        <div class="item">
          <div class="name">Liberdade 36 office building in Lisbon</div>
          <div class="desc">
            -Located in the core area of Liberdade, Lisbon <br />
            -Asset value exceeding 10 million euros <br />
            -Covering an area of 2352 square meters <br />
            -Rare locations, high-quality tenants <br />
            -High long-term lease with zero vacancy rate
          </div>
          <div class="img">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p19.png"
              alt=""
            />
          </div>
          <div class="img2">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p20.png"
              alt=""
            />
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p21.png"
              alt=""
            />
          </div>
        </div>
        <div class="item">
          <div class="name">Lisbon Fanqueiros 116 Four Star Hotel</div>
          <div class="desc">
            -Located in the old city center of Lisbon's political and business
            district<br />
            -A hotel with an area of 2500 square meters, 53 rooms, and a
            value-added four-star rating of millions of euros- Adjacent to
            Liberdade in Lisbon, one-stop shopping, consumption, leisure and
            tourism<br />
            -The demand for hotels is extremely high, with long-term leases and
            professional operator management to ensure stable revenue<br />
            -Renovation in progress, expected completion date 2025
          </div>
          <div class="img">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p22.png"
              alt=""
            />
          </div>
          <div class="img2">
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p23.png"
              alt=""
            />
            <img
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/portugal/p24.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isFold: true,
      btnText: "View more",
    };
  },
  beforeCreate() {
    document.title = "Fund in Portugal";
  },
  methods: {
    handleLookMore() {
      if (this.isFold) {
        this.isFold = false;
        this.btnText = "Collapse";
      } else {
        this.isFold = true;
        this.btnText = "View more";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-portugal {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 300px;
    background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/www/portugal/banner.png")
      no-repeat center center / cover;
    .logo {
      width: 106px;
    }
    .title {
      margin-top: 20px;
      padding-bottom: 46px;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
      }
    }
  }
  .textcontent {
    padding: 16px 22px 0;
    transition: all 0.3s;
    background: #fafafa;
    &.fold {
      height: 71px;
      overflow: hidden;
    }
    p {
      font-size: 14px;
      color: rgba(38, 38, 38, 1);
      line-height: 20px;
    }
  }
  .look-more {    
    padding: 20px 22px;
    background: #fafafa;
    z-index: 10;
    .opacity {
      position: absolute;
      height: 40px;
      width: calc(100% - 44px);
      margin: -40px 0 0px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        #fafafa,
        #fafafa
      );

      z-index: 10;
      // img {
      //   width: 100%;
      // }
    }
    .btn {
      margin: 0px 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      border-radius: 4px;
      border: 1px solid rgba(38, 38, 38, 1);
      &:active {
        opacity: 0.8;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
      }
      img {
        width: 8px;
        margin-left: 8px;
      }
    }
  }
  .approval {
    width: 100vw;
    // padding-top: 20px;
    // overflow-x: scroll;
    background: #fafafa;
    .hd {
      padding: 14px 0 14px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(38, 38, 38, 1);
      text-align: center;
    }
    .boxs {
      display: flex;
      justify-content: space-between;
      padding-left: 22px;
      .box {
        padding-right: 0px;
        &:last-child {
          padding-right: 20px;
        }
        .van-image {
          width: 105px;
          height: 147px;
          overflow: hidden;
          border-radius: 4px;
          border: 1px solid rgba(55, 55, 55, 0.5);
        }
      }
    }
  }
  .act {
    background: #fff;

    .title {
      padding-top: 10px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }

    .content {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 18px 18px 10px;
      .box {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.11);
        border-radius: 10px;
        padding: 12px 0px;
        margin-bottom: 12px;
        overflow: hidden;
        .subtitle {
          width: 110px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top: 1px solid #0052d9;
          border-bottom: 1px solid #0052d9;
          border-right: 1px solid #0052d9;
          transform: skewX(-20deg);
          font-family: PingFangSC-Medium, PingFang SC;
          font-size: 14px;
          font-weight: 500;
          color: #0052d9;
          text-align: center;
          padding: 6px 0 6px 8px;
          margin-bottom: 12px;
          position: relative;
          left: -10px;
          span {
            transform: skewX(20deg);
          }
        }
        .info {
          padding: 0px 10px;
          p {
            font-family: PingFangSC-Regular, PingFang SC;
            font-size: 11px;
            color: rgba(26, 26, 26, 1);
            line-height: 20px;
            span {
              color: #0052d9;
              font-weight: 600;
            }
          }
          .row-info-1 {
            font-family: PingFangSC-Medium, PingFang SC;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 8px;
          }
          .row-info-2 {
            font-family: PingFangSC-Semibold, PingFang SC;
            font-size: 11px;
            // font-weight: 600;
            text-align: justify;
            line-height: 15px;
          }
        }
      }
    }
  }
  .choose {
    .title {
      padding-bottom: 20px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-bottom: 16px;
      .box {
        background: #f2f3f5;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 16px 10px 16px;
        margin: 20px 18px 10px;
        border-radius: 4px;
        &:last-child {
          margin-bottom: 0;
        }
        .num {
          position: absolute;
          display: flex;
          margin-top: -26px;
          img {
            height: 20px;
          }
        }
        .info {
          p {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11px;
            color: #2c2c2c;
            line-height: 15px;
            text-align: justify;
            font-style: normal;
          }
        }
        &:hover {
          cursor: pointer;
          p {
            color: #003c7e;
            transform: scale(1.01);
            transition-duration: 0.3s;
          }
        }
      }
    }
  }
  .elements {
    background: #fafafa;
    .title {
      padding-top: 4px;
      padding-bottom: 2px;
      line-height: 14px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .content {
      .img1 {
        width: 100%;
        padding: 18px;
      }
      .img2 {
        width: 100%;
      }
    }

    .img-title {
      z-index: 20;
      display: flex;
      justify-content: center;
      position: relative;
      &::before {
        content: "";
        width: 2px;
        height: 20px;
        background: #d8dadd;
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
      }
      img {
        width: 60%;
        background: #fafafa;
      }
    }
    .line {
      z-index: 20;
      display: flex;
      justify-content: center;
      height: 30px;
      position: relative;
      &::before {
        content: "";
        width: 2px;
        height: 20px;
        background: #d8dadd;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
      }
      &::after {
        content: "";
        width: calc(50%);
        height: 1px;
        background: #d8dadd;
        position: absolute;
        top: 24px;
        left: 50%;
        transform: translateX(-50%);
      }
      .box {
        width: 50%;
        &::before {
          content: "";
          width: 1px;
          height: 23px;
          background: #d8dadd;
          position: absolute;
          top: 25px;
          left: 25%;
          transform: translateX(-50%);
        }
      }
      .box2 {
        width: 50%;
        &::before {
          content: "";
          width: 1px;
          height: 23px;
          background: #d8dadd;
          position: absolute;
          top: 25px;
          left: 75%;
          transform: translateX(-50%);
        }
      }
    }

    .img-title2 {
      display: flex;
      justify-content: center;
      position: relative;
      margin-bottom: 10px;
      img {
        width: 60%;
        background: #fafafa;
      }
    }

    .desc {
      display: flex;
      justify-content: center;
      margin: 0 10px;

      img {
        width: 100%;
      }
    }

    .box-wrap-container {
      margin: 18px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      position: relative;

      .box-wrap {
        width: calc(50% - 12px);
        background: #ffffff;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 12px 8px;
        margin-bottom: 12px;
        position: relative;

        .box-title {
          width: 30px;
          height: 25px;
          margin-bottom: 14px;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
        .row-1 {
          font-weight: 600;
          font-size: 12px;
          color: #252c39;
          line-height: 17px;
          margin-bottom: 4px;
          text-align: center;
          height: 34px;
        }
        .row-2 {
          font-weight: 400;
          font-size: 11px;
          color: #4e4e4e;
          line-height: 14px;
          text-align: center;
        }
      }
    }
  }

  .advantages {
    .title {
      padding-top: 4px;
      padding-bottom: 2px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }
    .content {
      background: #f3f8ff;
      border-radius: 8px;
      margin: 18px;
      padding: 15px 0px;

      .box {
        display: flex;
        align-items: center;
        margin: 0 15px 15px;

        .image {
          margin-right: 10px;
          img {
            width: 20px;
          }
        }
        .info {
          flex: 1;
          font-weight: 400;
          font-size: 11px;
          color: #2c2c2c;
          line-height: 15px;
          text-align: justify;
          font-style: normal;
        }
      }
    }
  }

  .introduction {
    .title {
      padding-top: 4px;
      padding-bottom: 2px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
        color: #003c7e;
        &::after {
          border-color: #003c7e;
        }
      }
    }

    .content {
      margin: 18px;
      .item {
        margin-bottom: 30px;
      }
      .name {
        font-weight: 600;
        font-size: 14px;
        color: #003c7e;
        margin-bottom: 4px;
      }
      .desc {
        font-weight: 400;
        font-size: 11px;
        color: #666666;
        margin-bottom: 4px;
        line-height: 18px;
      }
      .img {
        img {
          width: 100%;
        }
      }
      .img2 {
        display: flex;
        justify-content: space-between;
        img {
          width: calc(50% - 2px);
        }
      }
    }
  }
}
</style>
